<template>
  <div>
  <img id="logo" style="margin-top: 10px; border-radius: 20px;" height="230" width="300" class="centered" src="https://festive-chandrasekhar-aeb475.netlify.app/logo/bubbleid.jpg" alt="">
    <h1> BubbleID </h1>
    BubbleID allows creates a bubble over your data using SnailDOS services and expanding out to other services. We are growing our userbase. With encryption and privacy out of the box, you can create an account and start using SnailDOS services and apps for free, forever and private. 
    <p></p>
    For more infomation, please, check out our home page <a href="https://portal.snaildos.com">Can be found <b>HERE</b>.</a>
    <p></p>
    To get started, press the user logo at the top of the screen and create an account or login.
  </div>
</template>

<script>

export default {
name: "homepage",
  mounted() {
    // set background
    document.body.style.backgroundImage = "url('')";
  }
}
</script>

<style scoped>
h1,h2,h3,p,div {
  color: white;
}
</style>