<template>
  <Modal>
    <template v-slot:header>
      Editing account information
    </template>
    <b>Edit your account information here.</b><br><br>
    You're set name: {{ currentInfo.user }} <br>
    <Input name="name" type="text" v-model="form.username"/><br>
    Current email: {{ currentInfo.email }} <br>
    <Input name="Email" type="email" v-model="form.email"/>
    <Input name="New password" type="password" v-model="form.newPassword"/>
    <Input name="Current password" type="password" v-model="form.originalPassword"/>
    <VueHcaptcha sitekey="a04b537a-17d4-4a34-9092-ca0583ce459e" @verify="captchaCompleted" />
    <template v-slot:footer>
      <div style="color:#ff0000">{{ errorMessage }}</div>
      <Button @click="editAccount" :disabled="!captchaToken" type="submit">Edit</Button>
      <Button @click="$emit('close')" style="float:right">Cancel</Button>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal";
import Input from "@/components/Input";
import Button from "@/components/Button";
import ApiError from "@/api/ApiError";
import VueHcaptcha from "@hcaptcha/vue-hcaptcha";
import {client} from '@/main'

export default {
  name: "UserEditModal",
  components: {Button, Input, Modal, VueHcaptcha},
  async mounted() {
    await client.fetchProfile()
    this.currentInfo.user = client.user.name
    this.currentInfo.email = client.user.email
  },
  data() {
    return {
      errorMessage: "",
      captchaToken: "",
      currentInfo: {
        user: "",
        email: ""
      },
      form: {
        username: "",
        email: "",
        newPassword: "",
        originalPassword: ""
      }
    }
  },
  methods: {
    captchaCompleted(token) {
      this.captchaToken = token
    },
    async editAccount() {
      try {
        let data = {}
        if (this.form.username) data['name'] = this.form.username
        if (this.form.email) data['email'] = this.form.email
        if (this.form.newPassword) data['pass'] = this.form.newPassword
        data['originalPassword'] = this.form.originalPassword

        console.log(data)

        await client.editProfile(
            data,
            this.captchaToken
        )
        this.$emit('completed')
      } catch (e) {
        this.errorMessage = e.message
        if (e instanceof ApiError) {
          console.error(e)
        }
      }

    },
    updated() {
      const elements = document.getElementsByTagName('*')
      for (let i = 0, max = elements.length; i < max; i++) {
        try {
          // eslint-disable-next-line no-undef
          componentHandler.upgradeElement(elements[i])
          // eslint-disable-next-line no-empty
        } catch (e) {
        }
      }
    },
  }
}
</script>

<style scoped>

</style>