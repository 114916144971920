<template>
  <Modal>
    <template v-slot:header>
      Deleting your account
    </template>
    We have just sent you an email containing the verification code for your account deletion.<br>
    <Input name="Verification Code" v-model="verificationCode"/>
    <template v-slot:footer>
      <Button @click="deleteAccount" :disabled="!verificationCode">Delete my account</Button>
      <Button @click="$emit('close')" style="float:right">Cancel</Button>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal";
import Input from "@/components/Input";
import Button from "@/components/Button";
import { client } from '@/main'

export default {
name: "UserDeletionModal",
  components: {Button, Input, Modal},
  data() {
   return {
     verificationCode: ""
   }
  },
  methods: {
    deleteAccount() {
      client.deleteUserConfirm(this.verificationCode)
    }
  }
}
</script>

<style scoped>

</style>