import rest from "./rest"

export default class Client {
    constructor(token) {
        this.token = token || null
        this.rest = new rest(token)
    }

    get is_logged_in() {
        return this.token ? true : false
    }


    /**
    * Registers a user. validate(code) should then be called for verification.
    * **NOTE**: The client is authenticated beyond this point - no need to login!
    * @param {String} email
    * @param {String} name
    * @param {String} pass
    * @param {String} captcha
    */
    async register(email, name, pass, captcha) {
        const response = await this.rest.post('/users/create', {
            email,
            name,
            pass,
            captcha
        }, false)
        this.token = response.token
        return response
    }


    /**
     * Completes email verification for an user, activating the account.
     * @param {String} verificationCode A string in the form of "xxx-xxx"
     */
    async validate(verificationCode) {
        return this.rest.post('/users/validate', { verificationCode })
    }

    /**
     * Logs the user in. Not required if registeration was just completed.
     * @param {String} email The email address of the user
     * @param {String} pass The password of the user
     * @param {String} captcha The captcha
     * @returns 
     */
    async login(email, pass, captcha) {
        const response = await this.rest.post('/users/login', {
            email,
            pass,
            captcha
        }, false)
        this.token = response.token
        return response
    }


    /**
     * Fetches the user profile.
     */
    async fetchProfile() {
        const { user } = await this.rest.get('/users/@me')
        this.user = user
        return user
    }

    /**
     * Edits the user profile.
     * @param {Object} data The data of the profile
     * @param {String} [data.email] Email address of the user
     * @param {String} [data.name] Name of the user
     * @param {String} [data.pass] New password of the user
     * @param {String} [data.originalPassword] Current password of the user
     * @param {String} captcha The captcha token
     */
    async editProfile(data, captcha) {
        return this.rest.patch('/users/@me', Object.assign(data, { captcha }))
    }

    /**
     * Deletes the user profile. `deleteUserConfirm(deleteCode)` should then be called for validation.
     * @returns Le request
     */
    async deleteUser() {
        return this.rest.delete('/users/@me')
    }

    /**
     * Confirms the deletion request. The user is deleted beyond this point.
     * @param {string} deleteCode a string in the format of "xxx-xxx"
     */
    async deleteUserConfirm(deleteCode) {
        return this.rest.post('/@me/delete', { deleteCode })
    }

    /**
     * @type {String}
     */
     set token(token) {
        console.log(`Client: token has been set to ${token}`)
        if(this.rest) {
           this.rest.token = token
        }
        localStorage.setItem('token', token)
    }
}
