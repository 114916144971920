import Vue from 'vue'
import App from './App.vue'
import Client from './api/client'

Vue.config.productionTip = false

import VueRouter from "vue-router";
Vue.use(VueRouter)

import HomePage from "@/views/HomePage";
import Login from "@/views/Login";
import Register from "@/views/Register";
import User from "@/views/User";

const routes = [
  { path: '/', component: HomePage },
  { path: '/login', component: Login },
  { path: '/register', component: Register},
  { path: '/user', component: User}
]

const token = localStorage.getItem('token')

export const client = new Client(token)

// eslint-disable-next-line no-unused-vars
var app = new Vue({
  router: new VueRouter({mode: 'history', routes}),
  render: h => h(App),
  data: {
    client
  }
}).$mount('#app')
