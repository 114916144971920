<template>
  <div>
    <router-link v-if="logged_in" to="/user">
      <span class="mdl-chip mdl-chip--contact">
        <i class="material-icons mdl-chip__contact mdl-color--teal mdl-color-text--white">person</i>
        <span class="mdl-chip__text">{{this.username}}</span>
      </span>
    </router-link>

    <router-link v-else to="/login">
      <span class="mdl-chip mdl-chip--contact">
        <i class="material-icons mdl-chip__contact mdl-color--teal mdl-color-text--white">person</i>
        <span class="mdl-chip__text">Not Logged in</span>
      </span>
    </router-link>
  </div>
</template>

<script>
import { client } from "@/main"
export default {
  name: "UserCard",
  mounted() {this.syncLoginStatus()},
  methods: {
    async syncLoginStatus() {
      if(client.rest.token) {
        try {
          await client.fetchProfile()
          this.username = client.user.name
          this.logged_in = true
        } catch (e) {
          this.logged_in = false
        }
      }
      else this.logged_in = false
    }
  },
  watch: {
    "$root.client.rest.token"() {this.syncLoginStatus()}
  },
  data: function() {
    return {
      logged_in: false,
      username: "",
      profile: {
        sprite: "",
        seed: ""
      }
    }
  }
}
</script>

<style scoped>

</style>