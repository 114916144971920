<template>
  <div>
    <Loading v-if="loading" style="width: 100%"></Loading>
    <div class="demo-card-wide mdl-card">

      <div class="mdl-card__title">
        <h2 class="mdl-card__title-text mdl-color-text--black">Your Account</h2>
      </div>
      <!-- Delete/edit user -->
      <!-- Show user token -->
      <div class="mdl-card__supporting-text">
        Manage and edit your account information here.
      </div>
    </div>


    <div class="demo-card-wide mdl-card mdl-shadow--2dp">
      <div class="mdl-card__title">
        <h2 class="mdl-card__title-text mdl-color-text--black">Account Information</h2>
      </div>

      <div class="mdl-card__supporting-text">

        <div class="demo-list-action mdl-list">
          <div class="mdl-list__item">
            <span class="mdl-list__item-primary-content">
              <span>Name:</span>
            </span>
            <span class="mdl-list__item-secondary-content" href="#">
              {{ user.name }}
            </span>
          </div>

          <div class="mdl-list__item">
            <span class="mdl-list__item-primary-content">
              <span>Email:</span>
            </span>
            <span class="mdl-list__item-secondary-content">
             {{ user.email }}
            </span>
          </div>
        </div>
      </div>

      <div class="mdl-card__actions mdl-card--border">
        <Button v-on:click="logout">Log Out</Button>
        <Button style="float: right" @click="editModalOpen = true">Edit Information</Button>
      </div>
    </div>

    <div class="demo-card-wide mdl-card mdl-shadow--2dp w3-center">
      <div class="mdl-card__title w3-center">
        <br>
        <h2 class="mdl-card__title-text mdl-color-text--black">Account Token</h2>
        <br>

      </div>
      <div class="mdl-card__supporting-text spoilhover w3-center">
        Your account token is:
        <p></p>
        {{ token }}
      </div>
                <div class="mdl-card__supporting-text">
        <br>
        Please DO NOT share this account token with anyone. If your using a software, giving a account token gives access to your whole account, please ONLY put your account token in places that you trust!
      </div>
      <br>
    </div>

    <div class="demo-card-wide mdl-card mdl-shadow--2dp">
      <div class="mdl-card__title">
        <h2 class="mdl-card__title-text mdl-color-text--black">Delete your account</h2>
      </div>
      <div class="mdl-card__supporting-text">
        <b>
          Deletion of your account is irreversible. Once deletion is initiated, we will send a verification email to your registered email address.
        </b>
        <br>
      </div>
      <div class="mdl-card__actions mdl-card--border w3-center">
        I understand that deletion of my account is irreversible.
        <label class="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect" style="display: inline">
          <input type="checkbox" id="list-checkbox-1" class="mdl-checkbox__input" checked v-model="deleteConfirmed"/>
        </label>
        <Button @click="deleteAccount()" :disabled="!deleteConfirmed" class="w3-center">Delete my account</Button>
        <UserDeletionModal v-if="deleteModalOpen" @close="deleteModalOpen = false"/>
        <!-- TODO: delete confirmation-->
      </div>
    </div>
        <UserEditModal
            v-if="editModalOpen"
            @close="editModalOpen = false"
            @completed="editComplete"
            style="z-index: 13750913750971309"/>
  </div>
</template>

<script>
/* eslint-disable */
import VueHcaptcha from '@hcaptcha/vue-hcaptcha'
import Button from "@/components/Button";
import Input from "@/components/Input";
import Loading from "@/components/Loading";
import ApiError from "@/api/ApiError";
import UserDeletionModal from "@/modals/UserDeletionModal";
import UserEditModal from "@/modals/UserEditModal";
import { client } from "@/main.js"

export default {
  name: "user",
  data() {
    return {
      stage: 1,
      captcha: null,
      loading: false,
      errorMessage: null,
      user: {
        email: null,
        name: null,
      },
      verificationCode: null,
      token: null,
      editModalOpen: false,
      deleteModalOpen: false,
      deleteConfirmed: false
    }
  },
  components: {
    Loading,
    Input,
    Button,
    VueHcaptcha,
    UserDeletionModal,
    UserEditModal
  },
  async mounted() {
    // set background
    document.body.style.backgroundImage = "url('https://cloud.ibm.com/login/static/cache/2710b-1194401830/img/login_background.jpg')";
    document.body.style.backgroundSize = "cover"
    document.body.style.backgroundRepeat = "no-repeat";
    try {
      await client.fetchProfile()
      .catch((e) => {
        if(e instanceof ApiError) {
          this.logout()
        }
      })
      this.user.email = client.user.email
      this.user.name = client.user.name
      this.token = client.rest.token
    } catch (e) {
      if (e instanceof ApiError) {console.error(e)}
      if(e.statusCode === 401) {
        this.$router.replace('/')
      }
    }
  },
  methods: {
    logout() {
      client.token = null
      this.$router.push('/')
    },
    async deleteAccount() {
      try {
        await client.deleteUser()
      } catch(e) {
        if(e.code === "deletion.begins") { //deletion sequence already began
          return this.deleteModalOpen = true
        }
        console.log(e)
      }
      this.deleteModalOpen = true
    },
    editComplete() {
      this.$router.go()
    }
  }
}
</script>

<style scoped>
.demo-list-action {
  width: 300px;
}
.spoilhover {
    position: relative;
}

.spoilhover::before {
    content: "HOVER TO SHOW TOKEN";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    text-align: center;
    margin-left: 10px;
    background-color: #646464;
    border-radius: 6px;
    transition: all 2s ease-in-out 0s;
}

.spoilhover:hover::before {
    visibility: hidden;
    opacity: 0;
}

</style>