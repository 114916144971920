<template>
  <Modal>
    <template v-slot:header>
      Verify your account
    </template>
    You need to verify your email before proceeding. Check your emails for a verification code we sent.<br>
    <Input name="Verification Code" v-model="verificationCode"/>
    <template v-slot:footer>
      <Button @click="verifyAccount" :disabled="!verificationCode">Verify my account</Button>
      <Button @click="$emit('close')" style="float:right">Cancel and log out</Button>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal";
import Input from "@/components/Input";
import Button from "@/components/Button";
import ApiError from "@/api/ApiError";
import { client } from '@/main'

export default {
name: "UserVerifyModal",
  components: {Button, Input, Modal},
  data() {
   return {
     verificationCode: ""
   }
  },
  methods: {
    async verifyAccount() {
      try {
        await client.validate(this.verificationCode)
        this.$emit('verified')
      }
      catch(e) {
        if(e instanceof ApiError) {
          console.error(e)
        }
      }

    }
  }
}
</script>

<style scoped>

</style>