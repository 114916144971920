<template>
  <div class="mdl-progress mdl-js-progress mdl-progress__indeterminate"></div>
</template>

<script>
export default {
  name: "Loading",
  updated() {
    const elements = document.getElementsByTagName('*')
    for (let i=0, max=elements.length; i < max; i++) {
      try{
        // eslint-disable-next-line no-undef
        componentHandler.upgradeElement(elements[i])
        // eslint-disable-next-line no-empty
      } catch(e) {}
    }
  },
}
</script>

<style scoped>

</style>