<template>
  <button class="mdl-button mdl-js-button mdl-button--raised mdl-button--colored" @click="$emit('click')">
    <slot/>
  </button>
</template>

<script>
export default {
  name: "Button",
  updated() {
    const elements = document.getElementsByTagName('*')
    for (let i=0, max=elements.length; i < max; i++) {
      try{
        // eslint-disable-next-line no-undef
        componentHandler.upgradeElement(elements[i])
        // eslint-disable-next-line no-empty
      } catch(e) {}
    }
  },
}
</script>