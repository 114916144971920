<template>
  <div>
    <div class="modal-mask">
    </div>
    <div class="demo-card-wide mdl-card mdl-shadow--2dp">
      <div class="mdl-card__title">
        <h2 class="mdl-card__title-text mdl-color-text--black">
          <slot name="header">
            Hi
          </slot>
        </h2>
      </div>

      <div class="mdl-card__supporting-text">
        <slot>
          If you see this means there are funny thing happen
        </slot>
      </div>

      <div class="mdl-card__actions mdl-card--border">
        <slot name="footer" :emit="$emit">
          <Button @click="$emit('close')">OK</Button>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button";

export default {
  name: "Modal",
  components: {
    Button
  }
}
</script>

<style scoped>
.demo-card-wide.mdl-card {
  z-index: 69420;
  margin: auto;
  position: fixed;
  left: 50%;
  top: 169px;
  transform: translate(-50%);
}
.demo-card-wide > .mdl-card__title {
  height: 60px;
  color: #fff;
}

@media only screen and (max-width: 512px) {
}

.demo-list-action {
  width: 300px;
}
.modal-mask {
  position: fixed;
  z-index: 42069;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
</style>