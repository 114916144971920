<template>
  <div id="app" class="mdl-layout mdl-js-layout mdl-layout--fixed-header">
    <Header/>
    <main class="mdl-layout__content">
      <router-view/>
    </main>
    <Footer/>
  </div>
</template>

<script>
import Header from "@/components/TopNav";
import Footer from "@/components/Footer";

export default {
  name: 'App',
  components: {
    Header,
    Footer
  }
}
</script>

<style>
.demo-card-wide.mdl-card {
  width: 512px;
  margin: 20px auto auto;
  text-align: left;
  height: max-content;
  min-height: 0;
}
.demo-card-wide > .mdl-card__title {
  height: 50px;
  color:black;
}
@media only screen and (max-width: 512px) {
  .demo-card-wide.mdl-card {
    width: 100%;
  }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body {
  margin: 0;
  background-color: #141517;
}
</style>
