<template>
    <header class="mdl-layout__header">
      <div class="mdl-layout__header-row">
        <!-- Title -->
        <router-link to="/" class="mdl-layout-title">
          <span>SnailPortal</span>
        </router-link>
        <!-- Add spacer, to align navigation to the right -->
        <div class="mdl-layout-spacer"></div>
        <UserCard/>
      </div>
    </header>
</template>

<script>
import UserCard from '@/components/UserCard';
export default {
  name: "TopNav",
  components: {
    UserCard
  }
}
</script>

<style scoped>

.mdl-layout-title {
  text-decoration: none;
  color: white;
}

.mdl-layout__header {
  background-color: #161616 !important;
  opacity: 0.8 !important;
}

</style>