<template>
    <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
        <input 
            class="mdl-textfield__input"
            :type="type || 'text'"
            :required="required"
            :id="id || name.toLowerCase()"
            :value="value"
            v-on:input="$emit('input', $event.target.value)">
        <label
            class="mdl-textfield__label"
            :for="id || name.toLowerCase()">
            {{name}}
        </label>
    </div>
</template>

<script>
    export default {
        name: "Input",
        props: {
            type: String, //default to text
            name: String, //required
            id: String, //default to name.toLowerCase()
            required: Boolean, //default to false
            value: String,
        },
        mounted() {
          //fix for MDL dynamic
          const elements = document.getElementsByTagName('*')
          for (let i=0, max=elements.length; i < max; i++) {
            try{
              // eslint-disable-next-line no-undef
              componentHandler.upgradeElement(elements[i])
              // eslint-disable-next-line no-empty
            } catch(e) {}
          }
        }
    }
</script>