<template>
  <div class="mdl-mini-footer footer">
    <div class="mdl-mini-footer__left-section">
      <div class="mdl-logo">SnailDOS, All rights reserved</div>
      <ul class="mdl-mini-footer__link-list">
        <li><a href="https://community.snaildos.com">Help</a></li>
        <li><a href="https://docs.snaildos.com/en/EULA#snailportal-snailapi">Privacy & Terms</a></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>
footer {
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  width: 100%;
  z-index: 50;
}
</style>